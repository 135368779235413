import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { environment } from "src/environments/environment";
import { Order } from "src/app/shared/models/order.model";
import { map } from "rxjs/operators";
import { Developers } from "src/app/views/developers/developer.model";
import { Projects } from "src/app/views/projects/project.model";
import { Door } from "src/app/views/doors/door.model";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { doorUpload } from "../models/door-upload.model";
import { Apartments } from "src/app/views/apartments/appartment.model";
import { Extra } from "../models/extra.model";
import { Router, ActivatedRoute } from "@angular/router";
import { CalculateDoorPrice } from "../models/calculate-price.model";
import { Customer } from "../models/customer.model";
import { ApartmentService } from "./apartment.service";
import { Page } from "../models/page.model";
import { ToastrService } from "ngx-toastr";
import { ErrorHandlingService } from "./error-handling.service";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  doorForm: FormGroup;
  orders: any[] = [];
  currentOrder: Order;
  viewMode = "list";
  developers = [];
  customers: Customer[] = [];
  projects = [];
  apartments: Apartments[];
  currentApartment: Apartments;
  //availableDoors: Door[] = [];
  loading: boolean = false;
  route: ActivatedRoute;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private apartmentService: ApartmentService,
    private toastr: ToastrService,
    private errorService: ErrorHandlingService
  ) {}

  setDoorsReady() {
    let modifiedDoors: number[] = [];
    let count = 0;
    if (!this.currentOrder.apartment) {
      this.currentOrder.apartment = {
        id:null,
        reference:null,
        customer:null,
        project:null,
        doors:this.currentOrder.doors
      };
      //return;
    }
    for (let door of this.currentOrder.apartment.doors) {
      for (let exDoor of this.currentOrder.doors) {
        if (!exDoor.project_door) {
          continue;
        }
        if (+door.id == +exDoor.project_door.id && !modifiedDoors.includes(+door.id)) {
          //console.log("Replaced door " + door.id + " added ", exDoor);
          this.currentOrder.apartment.doors.splice(
            count,
            1,
            JSON.parse(JSON.stringify(exDoor))
          );
          modifiedDoors.push(door.id);
        }
      }
      count++;
    }
  }

  setDoorsReadyReverse() {
    let modifiedDoors: number[] = [];
    let count = 0;
    for (let door of this.currentOrder.doors) {
      for (let exDoor of this.currentOrder.apartment.doors) {
        if (!exDoor.project_door) {
          continue;
        }
        if (+door.id == +exDoor.id && !modifiedDoors.includes(+door.id)) {
          console.log("Replacing door " + door.id + " added ", exDoor);
          this.currentOrder.apartment.doors.splice(
            count,
            1,
            JSON.parse(JSON.stringify(exDoor))
          );
          modifiedDoors.push(door.id);
        }
      }
      count++;
    }
  }

  getPaymentTypes() {
    return this.http.get(`${environment.base_url}v1/order/paymenttype`);
  }

  getStatusColor(orderType: string): string {
    switch (orderType) {
      case "Quote": {
        return "#f4511e";
      }
      case "Sales Order": {
        return "#7cb342";
      }
      case "Measured": {
        return "#039be5";
      }
      case "Purchase Order": {
        return "#fdd835";
      }
      case "Installed": {
        return "#3949ab";
      }
      case "Partially Installed": {
        return "#8e24aa";
      }
      case "Complete": {
        return "#546e7a";
      }
      case "Denied": {
        return "#f44336";
      }
      case "Approved": {
        return "#cddc39";
      }
    }
    return "";
  }

  getOrders(queryParams?) {
    return this.http.get(`${environment.base_url}v1/order`, {
      params: queryParams,
    });
  }

  getDoorPrice(door: Door) {
    let projectId =  this.currentOrder.project ? +this.currentOrder.project.id : null;
    let extras: number[] = [];
    let extrasOutside: number[] = [];
    let calculatedPrice: CalculateDoorPrice;
    console.log("Testing: ", door);
    if (door.inside_face_extras) {
      for (let extra of door.inside_face_extras) {
        if (extra) {
          if (extra.id) {
            extras.push(extra.id);
          } else if (extra["extra"]) {
            if (extra["extra"]["id"]) {
              extras.push(extra["extra"]["id"]);
            }
          }
        }
      }
    }
    if (door.outside_face_extras) {
      for (let extra of door.outside_face_extras) {
        if (extra) {
          if (extra.id) {
            extrasOutside.push(extra.id);
          } else if (extra["extra"]) {
            if (extra["extra"]["id"]) {
              extrasOutside.push(extra["extra"]["id"]);
            }
          }
        }
      }
    }
    return this.http.post(`${environment.base_url}v1/door/calculateprice`, {
      inside_face_id: door.inside_face_door.id,
      outside_face_id: door.outside_face_door
        ? door.outside_face_door.id
        : null,
      quantity: door.quantity,
      has_installation: door.has_installation,
      inside_face_extras: extras,
      outside_face_extras: extrasOutside,
      project: projectId,
    });
  }

  getOrderStatuses() {
    return this.http.get(`${environment.base_url}v1/order/status`);
  }

  selectDoor(doorOld: Door, selectedDoor: any, isExternal?: boolean) {
    let isToggled = doorOld.toggle_outside;
    console.log("OLD: ", doorOld);
    let doorNew: Door = selectedDoor;
    console.log(doorNew);
    // for (let door of this.availableDoors) {
    //   if (
    //     +door.id ==
    //     (isExternal
    //       ? +this.doorForm.get("outside_face_door").value
    //       : +this.doorForm.get("inside_face_door").value)
    //   ) {
    //     doorNew = door;
    //     doorNew["suggested_door"] = null;
    //   }
    // }

    let projectDoorIds = [];

    if(this.currentOrder.apartment && this.currentOrder.apartment.doors){
      // for (let doorFinal of this.currentOrder.apartment.doors) {
      //   if (doorFinal.project_door) {
      //     if (doorFinal.project_door.id) {
      //       projectDoorIds.push(doorFinal.project_door.id);
      //     } else {
      //       projectDoorIds.push(doorFinal.project_door);
      //     }
      //   }
      // }

      for (let doorFinal of this.currentOrder.apartment.doors) {
        if (doorFinal.project_door) {
          let tmpDoor = {
            project_door: doorFinal["project_door"]["id"],
            height: doorFinal["height"],
            depth: doorFinal["depth"],
            width: doorFinal["width"],
            quantity: +doorFinal.quantity ? +doorFinal.quantity : 1,
            door_remarks: doorFinal["door_remarks"],
            allow_two_face: doorNew["allow_two_face"],
            inside_face_id: doorFinal["inside_face_door"]
              ? doorFinal["inside_face_door"]["id"]
              : null,
            inside_face_extras: null,
            outside_face_id: doorFinal["outside_face_door"]
              ? doorFinal["outside_face_door"]["id"]
              : null,
            outside_face_extras: null,
            gypsum_remarks: doorFinal["gypsum_remarks"],
            installer_remarks: doorFinal["installer_remarks"],
            not_needed: doorFinal["not_needed"],
            tile_remarks: doorFinal["tile_remarks"],
            has_installation: doorFinal["has_installation"],
            is_confirmed: doorFinal["is_confirmed"],
            is_opening_type_suggested: doorFinal["is_opening_type_suggested"],
            opening_type_suggested: doorFinal["opening_type_suggested"],
            opening_type_suggestion_remarks:
              doorFinal["opening_type_suggestion_remarks"],
            is_fanlight_suggested: doorFinal["is_fanlight_suggested"],
            fanlight_suggestion_remarks: doorFinal["fanlight_suggestion_remarks"],
            is_leaf_suggested: doorFinal["is_leaf_suggested"],
            leaf_suggested: doorFinal["leaf_suggested"],
            leaf_suggestion_remarks: doorFinal["leaf_suggestion_remarks"],
            is_installed: doorFinal["is_installed"],
            gypsum_width: doorFinal["gypsum_width"],
            gypsum_height: doorFinal["gypsum_height"],
            needs_gypsum_modification: doorFinal["needs_gypsum_modification"],
            needs_tile_modification: doorFinal["needs_tile_modification"],
            gypsum_modification_ready: doorFinal["gypsum_modification_ready"],
            tile_modification_ready: doorFinal["tile_modification_ready"],
            suggested_door: doorFinal["suggested_door"]
              ? doorFinal["suggested_door"]["id"]
              : doorFinal["suggested_door"],
            toggle_outside: isToggled,
            installer_extras_remarks: null,
          };
  
          if (
            !doorFinal.inside_face_extras &&
            !doorFinal.outside_face_extras &&
            projectDoorIds.includes(
              doorFinal.project_door.id
                ? doorFinal.project_door.id
                : doorFinal.project_door
            )
          ) {
            projectDoorIds.splice(
              projectDoorIds.indexOf(
                doorFinal.project_door.id
                  ? doorFinal.project_door.id
                  : doorFinal.project_door
              ),
              1
            );
            this.currentOrder.doors.push(JSON.parse(JSON.stringify(tmpDoor)));
          }
          if (
            doorFinal.inside_face_extras &&
            projectDoorIds.includes(
              doorFinal.project_door.id
                ? doorFinal.project_door.id
                : doorFinal.project_door
            )
          ) {
            projectDoorIds.splice(
              projectDoorIds.indexOf(
                doorFinal.project_door.id
                  ? doorFinal.project_door.id
                  : doorFinal.project_door
              ),
              1
            );
            if (doorFinal.inside_face_extras[0] == null) {
              this.currentOrder.doors.push(JSON.parse(JSON.stringify(tmpDoor)));
            }
          }
          if (
            doorFinal.outside_face_extras &&
            projectDoorIds.includes(
              doorFinal.project_door.id
                ? doorFinal.project_door.id
                : doorFinal.project_door
            )
          ) {
            projectDoorIds.splice(
              projectDoorIds.indexOf(
                doorFinal.project_door.id
                  ? doorFinal.project_door.id
                  : doorFinal.project_door
              ),
              1
            );
            if (doorFinal.outside_face_extras[0] == null) {
              this.currentOrder.doors.push(JSON.parse(JSON.stringify(tmpDoor)));
            }
          }
        }
      }

    }


    for (let doorCheck of this.currentOrder.doors) {
      if (doorCheck.project_door && doorOld.project_door) {
        if (+doorCheck.project_door.id == doorOld.project_door.id) {
          doorCheck.inside_face_extras = doorOld.inside_face_extras;
          doorCheck.toggle_outside = isToggled;
          isExternal
            ? (doorCheck.outside_face_door = JSON.parse(
                JSON.stringify(doorNew)
              ))
            : (doorCheck.inside_face_door = JSON.parse(
                JSON.stringify(doorNew)
              ));
          this.getDoorPrice(doorCheck).subscribe(
            (res) => {
              doorCheck["suggested_door"] = null;
              doorCheck.total_price = +res["data"]["total_sale"];
              console.log("This4->", doorCheck);
              doorCheck.extras_price = +res["data"]["total_extras"];
              console.log(doorCheck);
            },
            (err) => {}
          );

          // (isExternal ? doorCheck.outside_face_extras = null : doorCheck.inside_face_extras = null);
          doorCheck.isConfiguring = true;
          let c = 0;
          if (!isExternal) {
            doorCheck.inside_face_extras = null;
            if (doorCheck.outside_face_door) {
              if (
                doorCheck.outside_face_door.type["title"] !=
                doorCheck.inside_face_door.type["title"]
              ) {
                doorCheck.outside_face_door = null;
                doorCheck.outside_face_extras = null;
              }
            }
          } else {
            doorCheck.outside_face_extras = null;
          }
          for (let aptDoor of this.currentOrder.apartment.doors) {
            if (+aptDoor.id == +doorOld.id) {
              if (isToggled == true) {
                doorCheck["toggle_outside"] = true;
              }
              this.currentOrder.apartment.doors.splice(
                c,
                1,
                JSON.parse(JSON.stringify(doorCheck))
              );
              break;
            }
            if (aptDoor.project_door) {
              if (
                +aptDoor.project_door.id ==
                (doorOld.project_door ? +doorOld.project_door.id : doorOld.id)
              ) {
                if (isToggled == true) {
                  doorCheck["toggle_outside"] = true;
                }
                this.currentOrder.apartment.doors.splice(
                  c,
                  1,
                  JSON.parse(JSON.stringify(doorCheck))
                );
                break;
              }
            }

            c++;
          }

          this.updateOrderPrice();
          if (this.currentOrder.id) {
            localStorage.setItem(
              "current-order",
              JSON.stringify(this.currentOrder)
            );
          }
          console.log("1: ", this.currentOrder);
          this.allocateIds();
          return;
        }
      }
    }

    let extraList: Extra;
    console.log("DOOR NEW: ", doorNew);
    let newDoor: Door = isExternal
      ? {
          id: null,
          inside_face_door: doorOld["inside_face_door"],
          outside_face_door: {
            id: doorNew["id"],
            title: doorNew["title"],
            use_type: doorNew["use_type"]
              ? doorNew["use_type"]
              : doorNew["outside_face_door"]
              ? doorNew["outside_face_door"]["use_type"]
              : "Type Not Found?",
            available_extras: doorNew["available_extras"],
            type: doorNew["type"],
            reference: doorOld["reference"],
            image_url: doorNew["image_url"],
            sale_price: doorNew["sale_price"],
            cost_price: doorNew["cost_price"],
            installation_fee: doorNew["installation_fee"],
          },
          not_needed: false,
          allow_two_face: doorNew["allow_two_face"],
          quantity: doorNew["quantity"],
          sale_price: doorNew["sale_price"],
          extras_price: 0,
          installation_fee: 0,
          has_installation: false,
          total_price: 1,
          project_door: JSON.parse(
            JSON.stringify(
              doorOld.project_door ? doorOld.project_door : doorOld
            )
          ),
          inside_face_extras: [extraList],
          isConfiguring: true,
        }
      : {
          id: null,
          inside_face_door: {
            id: doorNew["id"],
            title: doorNew["title"],
            use_type: doorNew["use_type"]
              ? doorNew["use_type"]
              : doorNew["inside_face_door"]
              ? doorNew["inside_face_door"]["use_type"]
              : "Type Not Found?",
            available_extras: doorNew["available_extras"],
            type: doorNew["type"],
            reference: doorOld["reference"],
            image_url: doorNew["image_url"],
            sale_price: doorNew["sale_price"],
            cost_price: doorNew["cost_price"],
            installation_fee: doorNew["installation_fee"],
          },
          not_needed: false,
          quantity: doorNew["quantity"],
          sale_price: doorNew["sale_price"],
          extras_price: 0,
          installation_fee: 0,
          has_installation: false,
          total_price: 1,
          allow_two_face: doorNew["allow_two_face"],
          project_door: JSON.parse(
            JSON.stringify(
              doorOld.project_door ? doorOld.project_door : doorOld
            )
          ),
          inside_face_extras: [extraList],
          isConfiguring: true,
        };

    if (!isExternal) {
      if (newDoor.outside_face_door) {
        if (
          newDoor.outside_face_door.type["title"] !=
          newDoor.inside_face_door.type["title"]
        ) {
          newDoor.outside_face_door = null;
          newDoor.outside_face_extras = null;
        }
      }
    }

    newDoor.toggle_outside = isToggled;

    this.getDoorPrice(newDoor).subscribe(
      (res) => {
        //console.log("Door Costs: ",res['data']);
        newDoor["suggested_door"] = null;
        newDoor.total_price = +res["data"]["total_sale"];
        newDoor.extras_price = +res["data"]["total_extras"];
        console.log("This5->", newDoor);
      },
      (err) => {}
    );

    let c = 0;
    if(this.currentOrder.apartment && this.currentOrder.apartment.doors){
      for (let aptDoor of this.currentOrder.apartment.doors) {
        if (+aptDoor.id == +doorOld.id) {
          this.currentOrder.apartment.doors.splice(c, 1, newDoor);
          break;
        }
        if (aptDoor.project_door) {
          if (
            +aptDoor.project_door.id ==
            (doorOld.project_door ? +doorOld.project_door.id : doorOld.id)
          ) {
            this.currentOrder.apartment.doors.splice(c, 1, newDoor);
            break;
          }
        }
  
        c++;
      }
    }
    c = 0;
    for (let orderdoors of this.currentOrder.doors) {
      if (orderdoors.id == newDoor.project_door.id) {
        this.currentOrder.doors.splice(c, 1, newDoor);
      }
      c++;
    }
    this.allocateIds();
    this.updateOrderPrice();
    if (this.currentOrder.id) {
      localStorage.setItem("current-order", JSON.stringify(this.currentOrder));
    }
  }

  createForms(input: any) {
    this.doorForm = this.formBuilder.group({
      inside_face_door: [input.inside_face_door.id, Validators.required],
      outside_face_door: [input.outside_face_door],
      copyDoor: [""],
    });
  }

  hasExtra(doorCheck: Door, extra: Extra, isExternal?: boolean): boolean {
    //console.log(extra);
    if (!isExternal) {
      if (doorCheck.inside_face_extras) {
        for (let ext of doorCheck.inside_face_extras) {
          if (ext) {
            if (extra.id == (ext["extra"] ? ext["extra"]["id"] : ext.id)) {
              return true;
            }
          }
        }
      }
    } else {
      if (doorCheck.outside_face_extras) {
        for (let ext of doorCheck.outside_face_extras) {
          if (ext) {
            if (extra.id == (ext["extra"] ? ext["extra"]["id"] : ext.id)) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  hasExtraById(doorCheck: Door, extra: number, isExternal?: boolean): boolean {
    if (!isExternal) {
      if (doorCheck.inside_face_extras) {
        for (let ext of doorCheck.inside_face_extras) {
          if (ext) {
            if (extra == (ext["extra"] ? ext["extra"]["id"] : ext.id)) {
              return true;
            }
          }
        }
      }
    } else {
      if (doorCheck.outside_face_extras) {
        for (let ext of doorCheck.outside_face_extras) {
          if (ext) {
            if (extra == (ext["extra"] ? ext["extra"]["id"] : ext.id)) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  hasExtraOfType(doorCheck: Door, type: number, isExternal?: boolean): boolean {
    if (!isExternal) {
      if (doorCheck.inside_face_extras) {
        for (let ext of doorCheck.inside_face_extras) {
          if (ext) {
            //console.log(ext);
            if (
              type ==
              (ext["category"] ? ext["category"] : ext["extra"]["category"])[
                "type"
              ]["id"]
            ) {
              return true;
            }
          }
        }
      }
    } else {
      if (doorCheck.outside_face_extras) {
        for (let ext of doorCheck.outside_face_extras) {
          if (ext) {
            //console.log(ext);
            if (
              type ==
              (ext["category"] ? ext["category"] : ext["extra"]["category"])[
                "type"
              ]["id"]
            ) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  allocateIds() {
    let x = 10;
    for (let door of this.currentOrder.doors) {
      if (door.id == null) {
        door.id = door.project_door ? door.project_door.id : x;
        x++;
      }
    }
    if(this.currentOrder.apartment && this.currentOrder.apartment.doors){
      for (let door of this.currentOrder.apartment.doors) {
        if (door.id == null) {
          door.id = door.project_door ? door.project_door.id : x;
          x++;
        }
      }
    }
    //console.log(this.currentOrder);
  }

  modifyDoor(
    doorModify: Door,
    extraAdd: Extra,
    MainExtra: Extra,
    isExternal?: boolean
  ) {
    let _this = this;
    if (!isExternal) {
      if (
        doorModify.inside_face_extras == null ||
        !doorModify.inside_face_extras[0]
      ) {
        console.log("Called 3");
        doorModify.inside_face_extras = [extraAdd];
        this.hasRequiredExtras(doorModify);
        this.updatePerDoor(doorModify, isExternal);
        this.getDoorPrice(doorModify).subscribe(
          (res) => {
            doorModify.total_price = +res["data"]["total_sale"];
            doorModify.extras_price = +res["data"]["total_extras"];
            console.log("This->", doorModify);
          },
          (err) => {
            console.log(err);
          }
        );
        return;
      }
    } else {
      if (
        doorModify.outside_face_extras == null ||
        !doorModify.outside_face_extras[0]
      ) {
        doorModify.outside_face_extras = [extraAdd];
        console.log("Called 4");
        this.hasRequiredExtras(doorModify);
        this.updatePerDoor(doorModify, isExternal);
        this.getDoorPrice(doorModify).subscribe(
          (res) => {
            doorModify.total_price = +res["data"]["total_sale"];
            doorModify.extras_price = +res["data"]["total_extras"];
            console.log("This2->", doorModify);
          },
          (err) => {
            console.log(err);
          }
        );
        return;
      }
    }
    //console.log(doorModify);
    if (!isExternal) {
      for (let ext of doorModify.inside_face_door.available_extras) {
        for (let extComp of doorModify.inside_face_extras) {
          if (ext.id == extComp.id) {
            extComp.type = ext.type;
          }
        }
      }
    } else {
      for (let ext of doorModify.outside_face_door.available_extras) {
        for (let extComp of doorModify.outside_face_extras) {
          if (ext.id == extComp.id) {
            extComp.type = ext.type;
          }
        }
      }
    }
    // console.log(MainExtra);
    // console.log(extraAdd);

    if (!isExternal) {
      if (!this.hasExtra(doorModify, extraAdd)) {
        doorModify.inside_face_extras.push(extraAdd);
      } else {
        let c = 0;
        for (let ext of doorModify.inside_face_extras) {
          if (ext.id == extraAdd.id) {
            doorModify.inside_face_extras.splice(c, 1);
            break;
          }
          c++;
        }
      }
    } else {
      if (!this.hasExtra(doorModify, extraAdd, true)) {
        doorModify.outside_face_extras.push(extraAdd);
      } else {
        let c = 0;
        for (let ext of doorModify.outside_face_extras) {
          if (ext.id == extraAdd.id) {
            doorModify.outside_face_extras.splice(c, 1);
            break;
          }
          c++;
        }
      }
    }

    let c = -1;

    let array = isExternal
      ? doorModify.outside_face_extras
      : doorModify.inside_face_extras;

    for (let extra of array) {
      c++;
      //console.log("Door Extras: ",doorModify.extras);

      if (+extra.id != +extraAdd.id) {
        let allow_multiple = extra["extra"]
          ? extra["extra"]["category"]["type"]["allow_multiple"]
          : extra.category["type"]["allow_multiple"];
        let extraTypeId = extra["extra"]
          ? extra["extra"]["category"]["type"]["id"]
          : extra.category["type"]["id"];
        if (extraTypeId != MainExtra.type.id) {
          continue;
        }
        if (allow_multiple == false) {
          // console.log("Splicing1 : ",extra," index:",c);
          if (isExternal) {
            doorModify.outside_face_extras.splice(c, 1);
          } else {
            doorModify.inside_face_extras.splice(c, 1);
          }
          continue;
        }
        if (
          (extra["extra"]
            ? extra["extra"]["category"]["title"]
            : extra.category.title) == MainExtra.title
        ) {
          //  console.log("Splicing2 : ",extra," index:",c);
          if (isExternal) {
            doorModify.outside_face_extras.splice(c, 1);
          } else {
            doorModify.inside_face_extras.splice(c, 1);
          }
        }
      }
    }
    console.log("Called 5");
    this.hasRequiredExtras(doorModify);
    this.updatePerDoor(doorModify, isExternal);
    if (this.getDoorPrice(doorModify)) {
      this.getDoorPrice(doorModify).subscribe(
        (res) => {
          doorModify.total_price = +res["data"]["total_sale"];
          doorModify.extras_price = +res["data"]["total_extras"];
          console.log("This3->", doorModify);
        },
        (err) => {}
      );
    }

    let isInDoors: boolean = false;
    for (let door of this.currentOrder.doors) {
      if (door.project_door && doorModify.project_door) {
        if (door.project_door.id == doorModify.project_door.id) {
          isInDoors = true;
        }
      } else if (door.id && doorModify.id) {
        if (door.id == doorModify.id) {
          isInDoors = true;
        }
      }
    }

    if (!isInDoors) {
      _this.currentOrder.doors.push(doorModify);
    }
    console.log("Before Set Doors: ",this.currentOrder.doors);
    console.log("Before Set Apt Doors: ",this.currentOrder.apartment.doors);
    // this.setDoorsReadyReverse();
    // this.setDoorsReady();
    this.currentOrder.doors = this.currentOrder.apartment.doors;
    this.updateOrderPrice();
  }

  missingInsideTypes: Map<number, boolean> = new Map<number, boolean>();
  missingOutsideTypes: Map<number, boolean> = new Map<number, boolean>();

  updatePerDoor(door, isExternal?) {
    try {
      if (!isExternal) {
        for (let availableExtra of door.inside_face_extras) {
          if (
            (availableExtra["category"]
              ? availableExtra["category"]
              : availableExtra["extra"]["category"]
            ).type.is_per_door
          ) {
            if (door.outside_face_door) {
              if (door.outside_face_extras) {
                let extraIndex = 0;
                for (let doorExtra of door.outside_face_extras) {
                  if (
                    (doorExtra["category"]
                      ? doorExtra["category"]
                      : doorExtra["extra"]["category"]
                    ).type.is_per_door &&
                    (doorExtra["category"]
                      ? doorExtra["category"]
                      : doorExtra["extra"]["category"]
                    ).type.id ==
                      (availableExtra["category"]
                        ? availableExtra["category"]
                        : availableExtra["extra"]["category"]
                      ).type.id
                  ) {
                    door.outside_face_extras.splice(extraIndex, 1);
                  }
                  extraIndex++;
                }
              }
            }
          }
        }
      } else {
        for (let availableExtra of door.outside_face_extras) {
          if (
            (availableExtra["category"]
              ? availableExtra["category"]
              : availableExtra["extra"]["category"]
            ).type.is_per_door
          ) {
            if (door.inside_face_door) {
              if (door.inside_face_extras) {
                let extraIndex = 0;
                for (let doorExtra of door.inside_face_extras) {
                  if (
                    (doorExtra["category"]
                      ? doorExtra["category"]
                      : doorExtra["extra"]["category"]
                    ).type.is_per_door &&
                    (doorExtra["category"]
                      ? doorExtra["category"]
                      : doorExtra["extra"]["category"]
                    ).type.id ==
                      (availableExtra["category"]
                        ? availableExtra["category"]
                        : availableExtra["extra"]["category"]
                      ).type.id
                  ) {
                    door.inside_face_extras.splice(extraIndex, 1);
                  }
                  extraIndex++;
                }
              }
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  hasRequiredExtras(door): boolean {
    // let is_required = extra['extra'] ? extra['extra']['category']['type']['is_required'] :  extra.category['type']['is_required'];
    //   let is_per_door = extra['extra'] ? extra['extra']['category']['type']['is_per_door'] :  extra.category['type']['is_per_door'];
    if(door['not_needed']){
      return;
    }
    this.missingInsideTypes.clear();
    this.missingOutsideTypes.clear();
    try {
      for (let availableExtra of door.inside_face_door.available_extras) {
        if (availableExtra.type.is_required) {
          if (availableExtra.type.is_per_door) {
            if (this.hasExtraOfType(door, availableExtra.type.id, true)) {
              continue;
            }
          }
          if (!this.hasExtraOfType(door, availableExtra.type.id)) {
            this.missingInsideTypes.set(availableExtra.type.id, false);
          }
        }
      }
      if (door.outside_face_door) {
        for (let availableExtra of door.outside_face_door.available_extras) {
          if (availableExtra.type.is_required) {
            if (availableExtra.type.is_per_door) {
              if (this.hasExtraOfType(door, availableExtra.type.id, false)) {
                continue;
              }
            }
            if (!this.hasExtraOfType(door, availableExtra.type.id, true)) {
              this.missingOutsideTypes.set(availableExtra.type.id, true);
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
    return false;
  }

  updateOrderPrice() {
    // if (this.getOrderPrice()) {
    //   this.getOrderPrice().subscribe(res => {
    //     this.currentOrder['total_cost'] = +res['data']['total_cost'];
    //     this.currentOrder['total_net_vat'] = +res['data']['total_net_vat'];
    //     this.currentOrder['total_paid_by_developer'] = +res['data']['total_paid_by_developer'];
    //     this.currentOrder['total_discount'] = +res['data']['total_discount'];
    //     this.currentOrder['total_price'] = +res['data']['total_price'];
    //     this.currentOrder['installation_cost'] = +res['data']['installation_cost'];
    //     this.currentOrder['total_vat'] = +res['data']['total_vat'];
    //     if(this.currentOrder.id){
    //       localStorage.setItem("current-order", JSON.stringify(this.currentOrder));
    //     }
    //   });
    // }
  }

  getCurrentExtras(door: Door): Extra[] {
    let extraList: Extra[] = [];
    if (door.project_door) {
      if (door.inside_face_extras) {
        extraList.push(...door.inside_face_extras);
      }
      if (door.outside_face_extras) {
        extraList.push(...door.outside_face_extras);
      }
    }
    return extraList;
  }

  configureDoor(door: Door) {
    //this.doorForm.get('inside_face_door').reset();
    if (door.isConfiguring) {
      door.isConfiguring = false;
    } else {
      door.isConfiguring = true;
    }

    this.closeAllDoorsBar(door);
    if (this.currentOrder.id) {
      localStorage.setItem("current-order", JSON.stringify(this.currentOrder));
    }
  }

  removeConfigureDoor() {
    let c = 0;
    this.currentOrder.doors.forEach((val) => {
      val.isConfiguring = false;
      c++;
    });
  }

  deleteOrder(id) {
    this.http
      .delete(`${environment.base_url}v1/order/` + id)
      .subscribe((res) => {
        this.getOrders().subscribe((orders) => {
          this.orders = orders["data"]["data"];
        });
      });
    this.viewMode = "list";
  }

  loadOrder(id) {
    return this.http.get(`${environment.base_url}v1/order/` + id);
  }

  getQuote(orderId) {
    return this.http.post(
      `${environment.base_url}v1/order/` + orderId + `/pdf/quote`,
      {}
    );
  }

  getOrder(id) {
    //console.log("Loading ID ",id);
    this.viewMode = "edit";
    return this.http
      .get(`${environment.base_url}v1/order/` + id)
      .pipe(
        map((responseData) => {
          //console.log("OrderRes: ", responseData['data']);
          this.currentOrder = responseData["data"];
          console.log(this.currentOrder);
          let lastRevId = 0;
          if (responseData["data"]["revisions"]) {
            for (let rev of responseData["data"]["revisions"]) {
              lastRevId = +rev["id"];
            }
          }

          if (lastRevId > 0) {
            //console.log("Switched Id with "+lastRevId);
            this.currentOrder.id = lastRevId;
          }

          this.setDoorsReady();
          if (this.currentOrder.doors) {
            for (let door of this.currentOrder.doors) {
              door.toggle_outside = door.outside_face_door ? true : false;
              door.extras_price = door["total_extra_price"];
            }
          }

          if (
            this.currentOrder.apartment &&
            this.currentOrder.apartment.doors
          ) {
            for (let door of this.currentOrder.apartment.doors) {
              door.toggle_outside = door.outside_face_door ? true : false;
              door["extras_price"] = door["total_extra_price"];
            }
          }
          //console.log("Current-Order: ", this.currentOrder);
          return this.currentOrder;
        })
      )
      .subscribe((orders) => {});
  }

  getOrderPrice() {
    if (!this.currentOrder.id) {
      //console.log("Can't do this on an order that hasn't been created yet!");
      return;
    }
    let doorList: doorUpload[] = [];
    for (let door of this.currentOrder.apartment.doors) {
      let has_installation: boolean = door.has_installation ? true : false;
      let extraList: number[] = [];
      let extraListOutside: number[] = [];
      if (door.inside_face_extras) {
        for (let extra of door.inside_face_extras) {
          if (extra) {
            if (extra.id) {
              let extraId: number = +extra["id"];
              extraList.push(extraId);
            } else if (extra["extra"]["id"]) {
              let extraId: number = +extra["extra"]["id"];
              extraList.push(extraId);
            }
          }
        }
      }

      if (door.outside_face_extras) {
        for (let extra of door.outside_face_extras) {
          if (extra) {
            if (extra.id) {
              let extraId: number = +extra["id"];
              extraListOutside.push(extraId);
            } else if (extra["extra"]["id"]) {
              let extraId: number = +extra["extra"]["id"];
              extraListOutside.push(extraId);
            }
          }
        }
      }

      //console.log(door);
      if (door.inside_face_door) {
        let tmpDoor: doorUpload = {
          inside_face_id: +door.inside_face_door["id"],
          outside_face_id: +door.outside_face_door
            ? +door.outside_face_door["id"]
            : null,
          quantity: +door.quantity ? +door.quantity : 1,
          has_installation: has_installation ? 1 : 0,
          inside_face_extras: extraList,
          outside_face_extras: extraListOutside ? extraListOutside : null,
          not_needed: door.not_needed,
        };
        doorList.push(tmpDoor);
      }
    }
    return this.http.post(
      `${environment.base_url}v1/order/` +
        this.currentOrder.id +
        `/calculateprice`,
      {
        discount_amount: this.currentOrder.discount_amount,
        doors: doorList,
      }
    );
  }

  getOrderRevision(id) {
    return this.http
      .get(`${environment.base_url}v1/order/` + id)
      .pipe(
        map((responseData) => {
          this.currentOrder = responseData["data"];
          this.setDoorsReady();
          return this.currentOrder;
        })
      )
      .subscribe((orders) => {});
  }

  onEdit(postData, id) {
    return this.http
      .put(`${environment.base_url}v1/order/` + id, postData)
      .subscribe((responseData) => {});
  }

  postFile(file: FormGroup) {
    const formData = new FormData();
    formData.append("file", file.get("document").value);
    return this.http.post<any>(
      `${environment.base_url}v1/order/` + this.currentOrder.id + `/file`,
      formData
    );
  }

  postFileDoor(doorId, orderId, file: FormGroup) {
    const formData = new FormData();
    formData.append("file", file.get("document").value);
    return this.http.post<any>(
      `${environment.base_url}v1/order/` +
        orderId +
        `/door/` +
        doorId +
        `/file`,
      formData
    );
  }

  deleteFileDoor(fileId, orderId, doorId) {
    return this.http.delete<any>(
      `${environment.base_url}v1/order/` +
        orderId +
        `/door/` +
        doorId +
        `/file/` +
        fileId
    );
  }

  deleteFile(fileId) {
    return this.http.delete<any>(
      `${environment.base_url}v1/order/` +
        this.currentOrder.id +
        `/file/` +
        fileId
    );
  }

  getFiles() {
    return this.http.get(
      `${environment.base_url}v1/order/` + this.currentOrder.id
    );
  }

  getDevelopers() {
    return this.http.get(`${environment.base_url}v1/developer`).pipe(
      map((responseData) => {
        const postsArray: Developers[] = [];

        for (const key in responseData["data"]) {
          if (responseData["data"].hasOwnProperty(key)) {
            postsArray.push({ ...responseData["data"][key], web_id: key });
          }
        }
        return postsArray;
      })
    );
  }

  getCustomers() {
    return this.http.get(`${environment.base_url}v1/customer`).pipe(
      map((responseData) => {
        const postsArray: Customer[] = [];

        for (const key in responseData["data"]) {
          if (responseData["data"].hasOwnProperty(key)) {
            postsArray.push({ ...responseData["data"][key], web_id: key });
          }
        }

        for (let customer of postsArray) {
          customer.full_name =
            customer.first_name +
            " " +
            customer.last_name +
            (customer.reference ? " - " + customer.reference : "");
        }

        return postsArray;
      })
    );
  }

  getProjects() {
    return this.http.get(`${environment.base_url}v1/project`).pipe(
      map((responseData) => {
        const postsArray: Projects[] = [];

        for (const key in responseData["data"]) {
          if (responseData["data"].hasOwnProperty(key)) {
            postsArray.push({ ...responseData["data"][key], web_id: key });
          }
        }
        return postsArray;
      })
    );
  }

  getApartmentsProject(projectId: number) {
    return this.http.get(
      `${environment.base_url}v1/project/` + projectId + `/apartment`
    );
  }

  newOrder(projectless?) {
    let _this = this;
    let doorList: Map<number, doorUpload> = new Map<number, doorUpload>();

    if(!this.currentOrder.customer){
      this.toastr.error("You must select a customer.","Error!",{ timeOut: 3000, positionClass: "toast-top-center" });
      return;
    }

    let projectDoorIds = [];

    if(projectless){
      console.log("Saving Projectless Order");
      for(let door of this.currentOrder.doors){
        console.log(door);
        if(!door['inside_face_door']){
          this.toastr.error("You must select a door.","Error!",{ timeOut: 3000, positionClass: "toast-top-center" });
          return;
        }
      }
    }

    for (let doorFinal of this.currentOrder.apartment.doors) {
      if (doorFinal.project_door) {
        if (doorFinal.project_door.id) {
          projectDoorIds.push(doorFinal.project_door.id);
        } else {
          projectDoorIds.push(doorFinal.project_door);
        }
      }
    }

    for (let door of this.currentOrder.apartment.doors) {
      let has_installation: boolean = door.has_installation ? true : false;
      let extraList: number[] = [];
      let extraListOutside: number[] = [];

      if (door.inside_face_door) {
        console.log("Called 6");
        this.hasRequiredExtras(door);

        if (door.outside_face_door) {
          if (this.missingOutsideTypes.size > 0) {
            this.sendMissingTypeMessage(door.outside_face_door,this.missingOutsideTypes.size);
            return true;
          }
        }

        if (this.missingInsideTypes.size > 0) {
          this.sendMissingTypeMessage(door.inside_face_door,this.missingInsideTypes.size);
          return true;
        }
      }

      if (door.inside_face_extras) {
        for (let extra of door.inside_face_extras) {
          if (extra) {
            if (extra.id) {
              let extraId: number = +extra["id"];
              extraList.push(extraId);
            }
          }
        }
      }

      if (door.outside_face_extras) {
        for (let extra of door.outside_face_extras) {
          if (extra) {
            if (extra.id) {
              let extraId: number = +extra["id"];
              extraListOutside.push(extraId);
            }
          }
        }
      }

      if (door.inside_face_door) {
        if (!door.outside_face_door) {
          let tmpDoor: doorUpload = {
            inside_face_id: +door.inside_face_door["id"],
            outside_face_id: null,
            quantity: +door.quantity ? +door.quantity : 1,
            has_installation: has_installation ? 1 : 0,
            inside_face_extras: extraList,
            outside_face_extras: null,
            project_door: door.project_door.id,
            not_needed: door.not_needed,
          };
          tmpDoor["not_needed"] = door.not_needed;
          projectDoorIds.splice(
            projectDoorIds.indexOf(
              door.project_door.id ? door.project_door.id : door.project_door
            ),
            1
          );
          doorList.set(tmpDoor.project_door, tmpDoor);
        } else {
          let tmpDoor: doorUpload = {
            inside_face_id: +door.inside_face_door["id"],
            outside_face_id: +door.outside_face_door["id"],
            quantity: +door.quantity ? +door.quantity : 1,
            has_installation: has_installation ? 1 : 0,
            inside_face_extras: extraList,
            outside_face_extras: extraListOutside,
            project_door: door.project_door.id,
            not_needed: door.not_needed,
          };
          tmpDoor["not_needed"] = door.not_needed;
          projectDoorIds.splice(
            projectDoorIds.indexOf(
              door.project_door.id ? door.project_door.id : door.project_door
            ),
            1
          );
          doorList.set(tmpDoor.project_door, tmpDoor);
        }
      }
    }

    // inside_face_id: +door.inside_face_door['id'],
    // outside_face_id: +door.outside_face_door['id'],
    // quantity: +door.quantity ? +door.quantity : 1,
    // has_installation: has_installation ? 1 : 0,
    // inside_face_extras: extraList,
    // outside_face_extras: extraListOutside,
    // project_door: door.project_door.id
    for (let doorFinal of this.currentOrder.apartment.doors) {
      if (doorFinal.project_door) {
        let tmpDoor: doorUpload = {
          project_door: doorFinal["project_door"]["id"],
          quantity: +doorFinal.quantity ? +doorFinal.quantity : 1,
          inside_face_id: doorFinal["inside_face_door"]
            ? doorFinal["inside_face_door"]["id"]
            : null,
          inside_face_extras: null,
          outside_face_id: doorFinal["outside_face_door"]
            ? doorFinal["outside_face_door"]["id"]
            : null,
          outside_face_extras: null,
          has_installation: 0,
          not_needed: doorFinal["not_needed"] ? doorFinal["not_needed"] : false,
        };
        if (
          !doorFinal.inside_face_extras &&
          !doorFinal.outside_face_extras &&
          projectDoorIds.includes(
            doorFinal.project_door.id
              ? doorFinal.project_door.id
              : doorFinal.project_door
          )
        ) {
          projectDoorIds.splice(
            projectDoorIds.indexOf(
              doorFinal.project_door.id
                ? doorFinal.project_door.id
                : doorFinal.project_door
            ),
            1
          );
          doorList.set(tmpDoor.project_door, tmpDoor);
        }
        if (
          doorFinal.inside_face_extras &&
          projectDoorIds.includes(
            doorFinal.project_door.id
              ? doorFinal.project_door.id
              : doorFinal.project_door
          )
        ) {
          projectDoorIds.splice(
            projectDoorIds.indexOf(
              doorFinal.project_door.id
                ? doorFinal.project_door.id
                : doorFinal.project_door
            ),
            1
          );
          if (doorFinal.inside_face_extras[0] == null) {
            doorList.set(tmpDoor.project_door, tmpDoor);
          }
        }
        if (
          doorFinal.outside_face_extras &&
          projectDoorIds.includes(
            doorFinal.project_door.id
              ? doorFinal.project_door.id
              : doorFinal.project_door
          )
        ) {
          projectDoorIds.splice(
            projectDoorIds.indexOf(
              doorFinal.project_door.id
                ? doorFinal.project_door.id
                : doorFinal.project_door
            ),
            1
          );
          if (doorFinal.outside_face_extras[0] == null) {
            doorList.set(tmpDoor.project_door, tmpDoor);
          }
        }
      }
    }

    let orderData;

    if(projectless){
      console.log("Saving Projectless Order");
      for(let door of this.currentOrder.doors){
        if(door['id']){
          
        }
        if(door.inside_face_extras){
          let tmp = [];
          for(let ext of door.inside_face_extras){
            if(ext['id']){
              tmp.push(ext['id']);
            }
          }
          door.inside_face_extras = tmp;
        }

        if(door.outside_face_extras){
          let tmp = [];
          for(let ext of door.outside_face_extras){
            if(ext['id']){
              tmp.push(ext['id']);
            }
          }
          door.outside_face_extras = tmp;
        }
      }
    }

    if (this.currentOrder.project) {
      orderData = {
        developer: +this.currentOrder.developer["id"],
        customer: +this.currentOrder.customer.id,
        project: +this.currentOrder.project.id,
        apartment: +this.currentApartment.id,
        remarks: this.currentOrder.remarks,
        payment_method: "cash",
        discount_amount: +this.currentOrder.discount_amount,
        doors: Array.from(doorList.values()),
      };
    } else {
      orderData = {
        developer: null,
        customer: +this.currentOrder.customer.id,
        project: null,
        apartment: null,
        remarks: this.currentOrder.remarks,
        payment_method: "cash",
        discount_amount: +this.currentOrder.discount_amount,
        doors: [],
      };
      console.log(orderData);
      for (let d of this.currentOrder.doors) {
        let obj: any = {
          inside_face_id: d.inside_face_door ? d.inside_face_door.id : null,
          outside_face_id: d.outside_face_door ? d.outside_face_door.id : null,
          quantity: 1,
          has_installation: 0,
          inside_face_extras: d.inside_face_extras,
          outside_face_extras: d.outside_face_extras,
          project_door: d,
          not_needed: d.not_needed,
        };
        orderData["doors"].push(obj);
        //console.log(obj)
      }
      //return;
    }
    // console.log(this.currentOrder);
    // return;
    this.http.post(`${environment.base_url}v1/order`, orderData).subscribe(
      (res) => {
        if(_this.currentOrder.project){
          this.apartmentService.updateCustomer(
            this.currentOrder.project.id,
            this.currentOrder.apartment.id,
            this.currentOrder.customer.id,
            this.currentApartment.reference
          )
          .subscribe(
            (responseData) => {
              //console.log('Apt-Res: ',responseData);
            },
            (err) => {
              //console.log('Apt-Res: ',err);
            }
          );
        }
        localStorage.removeItem("current-order");
        this.router.navigate(["/orders/view/" + res["data"]["id"]]);
        this.toastr.success("Order Created Successfully!", "Success!", {
          timeOut: 3000,
          positionClass: "toast-top-center",
        });
        //this.getOrder(+res['data']['id']);
        this.getOrders().subscribe((orders) => {
          _this.loading = false;
          this.orders = orders["data"]["data"];
        });
      },
      (err) => {
        _this.loading = false;
        this.errorService.getErrors(err);
        this.toastr.error(
          this.errorService.getErrors(err).join(","),
          "Error!",
          { timeOut: 3000, positionClass: "toast-top-center" }
        );
        //console.log(err);
      }
    );
  }

  saveOrder(installerModule: boolean, moveStep?) {
    //let doorList: any[] = [];
    let doorList: Map<number, any> = new Map<number, any>();
    let installer_extras = {};
    let _this = this;
    //if(moveStep)
    //this.setDoorsReadyReverse();
    console.log(_this.currentOrder);

    this.setDoorsReady();

    for (let doorLoop of _this.currentOrder.apartment.doors) {
      let doorIndex = 0;
      for (let doorCheck of _this.currentOrder.doors) {
        if (doorLoop.id == doorCheck.id && doorCheck.not_needed == false) {
          _this.currentOrder.doors[doorIndex].inside_face_extras = doorLoop.inside_face_extras ? [...doorLoop.inside_face_extras] : [];
          _this.currentOrder.doors[doorIndex].outside_face_extras = doorLoop.outside_face_extras ? [...doorLoop.outside_face_extras] : [];
          console.log(_this.currentOrder.doors[doorIndex]);
        }
        doorIndex++;
      }
    }

    console.log(_this.currentOrder);

    for (let door of this.currentOrder.doors) {
      let extraList: number[] = [];
      let extraListOutside: number[] = [];
      installer_extras = {};

      if (door.inside_face_door) {
        console.log("Called 1");
        this.hasRequiredExtras(door);

        if (door.outside_face_door) {
          if (this.missingOutsideTypes.size > 0) {
            this.sendMissingTypeMessage(door.outside_face_door,this.missingOutsideTypes.size);
            return true;
          }
        }

        if (this.missingInsideTypes.size > 0) {
          this.sendMissingTypeMessage(door.inside_face_door,this.missingInsideTypes.size);
          return true;
        }
      }

      if (door.inside_face_extras) {
        for (let extra of door.inside_face_extras) {
          if (installerModule) {
            if (extra["extra"]["id"]) {
              let extraId: number = +extra["extra"]["id"];
              extraList.push(extraId);
            }
          } else {
            if (extra.id) {
              let extraId: number = +extra["id"];
              extraList.push(extraId);
            }
          }
        }

        for (let extra of door.inside_face_extras) {
          if (extra) {
            if (extra["extra"]) {
              if (extra["extra"]["id"]) {
                //console.log("Check this: ",extra);
                installer_extras[extra["extra"]["id"]] = {
                  is_confirmed: extra["is_confired"],
                  suggested_extra: extra["suggested_extra"]
                    ? extra["suggested_extra"]["id"]
                    : null,
                  remarks: extra["remarks"],
                };
              }
            }
          }
        }
      }

      if (door.outside_face_extras) {
        for (let extra of door.outside_face_extras) {
          if (installerModule) {
            if (extra["extra"]["id"]) {
              let extraId: number = +extra["extra"]["id"];
              extraListOutside.push(extraId);
            }
          } else {
            if (extra.id) {
              let extraId: number = +extra["id"];
              extraListOutside.push(extraId);
            }
          }
        }

        for (let extra of door.outside_face_extras) {
          if (extra) {
            if (extra["extra"]) {
              if (extra["extra"]["id"]) {
                //console.log("Check this: ",extra);
                installer_extras[extra["extra"]["id"]] = {
                  is_confirmed: extra["is_confired"],
                  suggested_extra: extra["suggested_extra"]
                    ? extra["suggested_extra"]["id"]
                    : null,
                  remarks: extra["remarks"],
                };
              }
            }
          }
        }
      }

      if (door.outside_face_door) {
        let tmpDoor = {
          project_door: door["project_door"]["id"],
          height: door["height"],
          depth: door["depth"],
          width: door["width"],
          quantity: +door.quantity ? +door.quantity : 1,
          door_remarks: door["door_remarks"],
          inside_face_id: door["inside_face_door"]["id"],
          inside_face_extras: extraList,
          outside_face_id: door["outside_face_door"]["id"],
          outside_face_extras: extraListOutside,
          gypsum_remarks: door["gypsum_remarks"],
          installer_remarks: door["installer_remarks"],
          not_needed: door["not_needed"],
          tile_remarks: door["tile_remarks"],
          has_installation: door["has_installation"],
          is_confirmed: door["is_confirmed"],
          is_opening_type_suggested: door["is_opening_type_suggested"],
          opening_type_suggested: door["opening_type_suggested"],
          opening_type_suggestion_remarks:
            door["opening_type_suggestion_remarks"],
          is_fanlight_suggested: door["is_fanlight_suggested"],
          fanlight_suggestion_remarks: door["fanlight_suggestion_remarks"],
          is_leaf_suggested: door["is_leaf_suggested"],
          leaf_suggested: door["leaf_suggested"],
          leaf_suggestion_remarks: door["leaf_suggestion_remarks"],
          is_installed: door["is_installed"],
          gypsum_width: door["gypsum_width"],
          gypsum_height: door["gypsum_height"],
          needs_gypsum_modification: door["needs_gypsum_modification"],
          needs_tile_modification: door["needs_tile_modification"],
          gypsum_modification_ready: door["gypsum_modification_ready"],
          tile_modification_ready: door["tile_modification_ready"],
          suggested_door: door["suggested_door"]
            ? door["suggested_door"]["id"]
            : door["suggested_door"],
          installer_extras_remarks: installer_extras,
        };
        doorList.set(tmpDoor.project_door, tmpDoor);
      } else {
        if (door["inside_face_door"]) {
          let tmpDoor = {
            project_door: door["project_door"]["id"],
            height: door["height"],
            depth: door["depth"],
            width: door["width"],
            quantity: +door.quantity ? +door.quantity : 1,
            door_remarks: door["door_remarks"],
            inside_face_id: door["inside_face_door"]["id"],
            inside_face_extras: extraList,
            gypsum_remarks: door["gypsum_remarks"],
            installer_remarks: door["installer_remarks"],
            not_needed: door["not_needed"],
            tile_remarks: door["tile_remarks"],
            has_installation: door["has_installation"],
            is_confirmed: door["is_confirmed"],
            is_opening_type_suggested: door["is_opening_type_suggested"],
            opening_type_suggested: door["opening_type_suggested"],
            opening_type_suggestion_remarks:
              door["opening_type_suggestion_remarks"],
            is_fanlight_suggested: door["is_fanlight_suggested"],
            fanlight_suggestion_remarks: door["fanlight_suggestion_remarks"],
            is_leaf_suggested: door["is_leaf_suggested"],
            leaf_suggested: door["leaf_suggested"],
            leaf_suggestion_remarks: door["leaf_suggestion_remarks"],
            is_installed: door["is_installed"],
            gypsum_width: door["gypsum_width"],
            gypsum_height: door["gypsum_height"],
            needs_gypsum_modification: door["needs_gypsum_modification"],
            needs_tile_modification: door["needs_tile_modification"],
            gypsum_modification_ready: door["gypsum_modification_ready"],
            tile_modification_ready: door["tile_modification_ready"],
            suggested_door: door["suggested_door"]
              ? door["suggested_door"]["id"]
              : door["suggested_door"],
            installer_extras_remarks: installer_extras,
          };
          doorList.set(tmpDoor.project_door, tmpDoor);
          //doorList.push(tmpDoor);
        }
      }
    }

    if (!installerModule) {
      if (this.currentOrder.apartment) {
        for (let door of this.currentOrder.apartment.doors) {
          if (door.inside_face_door) {
            console.log("Called 2");
            this.hasRequiredExtras(door);

            if (door.outside_face_door) {
              if (this.missingOutsideTypes.size > 0) {
                this.sendMissingTypeMessage(door.outside_face_door,this.missingOutsideTypes.size);
                return true;
              }
            }

            if (this.missingInsideTypes.size > 0) {
              this.sendMissingTypeMessage(door.inside_face_door,this.missingInsideTypes.size);
              return true;
            }
          }

          for (let doorListCheck of doorList.values()) {
            if (
              (door["project_door"] ? door["project_door"]["id"] : door.id) ==
              doorListCheck.project_door
            ) {
              if (door.inside_face_extras) {
                for (let extra of door.inside_face_extras) {
                  if (extra["extra"]) {
                    let tmpExtra: Extra = extra["extra"]
                      ? extra["extra"]
                      : extra;
                    if (
                      !doorListCheck.inside_face_extras.includes(tmpExtra.id)
                    ) {
                      doorListCheck.inside_face_extras.push(tmpExtra.id);
                    }
                  } else if (extra["id"]) {
                    if (
                      !doorListCheck.inside_face_extras.includes(extra["id"])
                    ) {
                      doorListCheck.inside_face_extras.push(extra["id"]);
                    }
                  }
                }
              }

              if (door.outside_face_extras) {
                for (let extra of door.outside_face_extras) {
                  if (extra["extra"]) {
                    let tmpExtra: Extra = extra["extra"]
                      ? extra["extra"]
                      : extra;
                    if (
                      !doorListCheck.outside_face_extras.includes(tmpExtra.id)
                    ) {
                      doorListCheck.outside_face_extras.push(tmpExtra.id);
                    }
                  } else if (extra["id"]) {
                    if (
                      !doorListCheck.outside_face_extras.includes(extra["id"])
                    ) {
                      doorListCheck.outside_face_extras.push(extra["id"]);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    this.loading = true;
    if (moveStep == 1) {
      let isTrue = false;
      for (let door of this.currentOrder.apartment.doors) {
        if (!door.inside_face_door) {
          isTrue = true;
        }
      }
      if (isTrue) {
        if (
          !confirm(
            "Some doors are not configured. Are you sure you want to continue?"
          )
        ) {
          _this.loading = false;
          return;
        }
      }
    }

    let projectDoorIds = [];

    for (let doorFinal of this.currentOrder.apartment.doors) {
      if (doorFinal.project_door) {
        if (doorFinal.project_door.id) {
          projectDoorIds.push(doorFinal.project_door.id);
        } else {
          projectDoorIds.push(doorFinal.project_door);
        }
      }
    }

    for (let doorFinal of this.currentOrder.apartment.doors) {
      if (doorFinal.project_door) {
        let tmpDoor = {
          project_door: doorFinal["project_door"]["id"],
          height: doorFinal["height"],
          depth: doorFinal["depth"],
          width: doorFinal["width"],
          quantity: +doorFinal.quantity ? +doorFinal.quantity : 1,
          door_remarks: doorFinal["door_remarks"],
          inside_face_id: doorFinal["inside_face_door"]
            ? doorFinal["inside_face_door"]["id"]
            : null,
          inside_face_extras: null,
          outside_face_id: doorFinal["outside_face_door"]
            ? doorFinal["outside_face_door"]["id"]
            : null,
          outside_face_extras: null,
          gypsum_remarks: doorFinal["gypsum_remarks"],
          installer_remarks: doorFinal["installer_remarks"],
          not_needed: doorFinal["not_needed"],
          tile_remarks: doorFinal["tile_remarks"],
          has_installation: doorFinal["has_installation"],
          is_confirmed: doorFinal["is_confirmed"],
          is_opening_type_suggested: doorFinal["is_opening_type_suggested"],
          opening_type_suggested: doorFinal["opening_type_suggested"],
          opening_type_suggestion_remarks:
            doorFinal["opening_type_suggestion_remarks"],
          is_fanlight_suggested: doorFinal["is_fanlight_suggested"],
          fanlight_suggestion_remarks: doorFinal["fanlight_suggestion_remarks"],
          is_leaf_suggested: doorFinal["is_leaf_suggested"],
          leaf_suggested: doorFinal["leaf_suggested"],
          leaf_suggestion_remarks: doorFinal["leaf_suggestion_remarks"],
          is_installed: doorFinal["is_installed"],
          gypsum_width: doorFinal["gypsum_width"],
          gypsum_height: doorFinal["gypsum_height"],
          needs_gypsum_modification: doorFinal["needs_gypsum_modification"],
          needs_tile_modification: doorFinal["needs_tile_modification"],
          gypsum_modification_ready: doorFinal["gypsum_modification_ready"],
          tile_modification_ready: doorFinal["tile_modification_ready"],
          suggested_door: doorFinal["suggested_door"]
            ? doorFinal["suggested_door"]["id"]
            : doorFinal["suggested_door"],
          installer_extras_remarks: installer_extras,
        };
        if (
          !doorFinal.inside_face_extras &&
          !doorFinal.outside_face_extras &&
          projectDoorIds.includes(
            doorFinal.project_door.id
              ? doorFinal.project_door.id
              : doorFinal.project_door
          )
        ) {
          projectDoorIds.splice(
            projectDoorIds.indexOf(
              doorFinal.project_door.id
                ? doorFinal.project_door.id
                : doorFinal.project_door
            ),
            1
          );
          //doorList.push(tmpDoor);
          doorList.set(tmpDoor.project_door, tmpDoor);
        }
        if (
          doorFinal.inside_face_extras &&
          projectDoorIds.includes(
            doorFinal.project_door.id
              ? doorFinal.project_door.id
              : doorFinal.project_door
          )
        ) {
          projectDoorIds.splice(
            projectDoorIds.indexOf(
              doorFinal.project_door.id
                ? doorFinal.project_door.id
                : doorFinal.project_door
            ),
            1
          );
          if (doorFinal.inside_face_extras[0] == null) {
            //doorList.push(tmpDoor);
            doorList.set(tmpDoor.project_door, tmpDoor);
          }
        }
        if (
          doorFinal.outside_face_extras &&
          projectDoorIds.includes(
            doorFinal.project_door.id
              ? doorFinal.project_door.id
              : doorFinal.project_door
          )
        ) {
          projectDoorIds.splice(
            projectDoorIds.indexOf(
              doorFinal.project_door.id
                ? doorFinal.project_door.id
                : doorFinal.project_door
            ),
            1
          );
          if (doorFinal.outside_face_extras[0] == null) {
            //doorList.push(tmpDoor);
            doorList.set(tmpDoor.project_door, tmpDoor);
          }
        }
      }
    }

    console.log(Array.from(doorList.values()));
    _this.loading = false;
    console.log(this.currentOrder);
    console.log("Door List: ", doorList);
    //return;
    //this.router.navigate(["/dashboard"]);
    this.http
      .put(`${environment.base_url}v1/order` + "/" + this.currentOrder.id, {
        developer: this.currentOrder.developer
          ? +this.currentOrder.developer["id"]
          : null,
        customer: +this.currentOrder.customer.id,
        project: this.currentOrder.project
          ? +this.currentOrder.project.id
          : null,
        apartment: (this.currentOrder.project && this.currentOrder.apartment)
          ? +this.currentOrder.apartment.id
          : null,
        remarks: this.currentOrder.remarks,
        payment_method: this.currentOrder.payment_method,
        cheque_reference: this.currentOrder["cheque_reference"],
        update_status: moveStep == 1 ? 1 : 0,
        discount_amount: +this.currentOrder.discount_amount,
        doors: Array.from(doorList.values()),
      })
      .subscribe(
        (res) => {
          if (moveStep == "quote") {
            this.getQuote(res["data"]["id"]).subscribe(
              (res2) => {
                window.open(res2["data"]["url"]);
                _this.loading = false;
              },
              (err) => {
                _this.loading = false;
                console.log(err);
              }
            );
          } else if (res["data"]["status"]["id"] == 2 && moveStep == 1) {
            window.open(res["data"]["finance_files"][0]["file_url"]);
          }

          this.getOrder(res["data"]["id"]);
          localStorage.removeItem("current-order");

          if (installerModule) {
            this.toastr.success("Order Updated Successfully!", "Success!", {
              timeOut: 3000,
              positionClass: "toast-top-center",
            });
            let urlActual = this.route.snapshot.url[0]["path"];
            _this.loading = false;
            this.router.navigate([
              (moveStep ? "installations/measured/" : "installations/sales/") + res["data"]["id"],
            ]);
          } else {
            this.toastr.success("Order Updated Successfully!", "Success!", {
              timeOut: 3000,
              positionClass: "toast-top-center",
            });
            if (!moveStep) {
              _this.loading = false;
              this.router.navigate(["/orders/view/" + res["data"]["id"]]);
            } else {
              _this.loading = false;
              this.router.navigate([
                "/orders/filtered/" + res["data"]["status"]["id"],
              ]);
            }
          }
        },
        (err) => {
          _this.loading = false;
          this.toastr.error(
            this.errorService.getErrors(err).join(","),
            "Error!",
            { timeOut: 3000, positionClass: "toast-top-center" }
          );
        }
      );
  }

  sendMissingTypeMessage(door,size){
    this.toastr.error(`Door ${door['title']} Missing ${size} required types.`, "Error!", {
      timeOut: 3000,
      positionClass: "toast-top-center",
    });
  }

  markAsPaid(payment_method) {
    return this.http.put(
      `${environment.base_url}v1/order/` + this.currentOrder.id + "/paid",
      { payment_method: payment_method }
    );
  }

  getStatusInfo(statusId) {
    switch (statusId) {
      case 1:
        return { name: "Quotes", icon: "i-Receipt-3" };
      case 2:
        return { name: "Sales Orders", icon: "i-Post-Sign-2-ways" };
      case 3:
        return { name: "Measured Orders", icon: "i-Box-Full" };
      case 4:
        return { name: "Purchase Orders", icon: "i-Cash-register-2" };
      case 5:
        return { name: "Installed Orders", icon: "i-Diploma-2" };
      case 6:
        return { name: "Partially Installed", icon: "i-Newspaper" };
      case 7:
        return { name: "Complete", icon: "i-Calendar-4" };
        case 8:
          return { name: "Denied Orders", icon: "fal fa-times" };
        case 9:
          return { name: "Approved Orders", icon: "fal fa-check" };
    }
  }

  closeAllDoorsBar(doorOpen: Door) {
    if (this.currentOrder) {
      if (this.currentOrder.doors) {
        for (let door of this.currentOrder.doors) {
          if (door.id != doorOpen.id) {
            door.isConfiguring = false;
          }
        }
      }

      if (this.currentOrder.apartment.doors) {
        for (let door of this.currentOrder.apartment.doors) {
          if (door.id != doorOpen.id) {
            door.isConfiguring = false;
          }
        }
      }
    }
    return;
  }

  getDoorIfExists(door: Door): Door {
    let openingId = door.id;
    for (let door of this.currentOrder.doors) {
      if (+door.project_door.id == +openingId) {
        return door;
      }
    }
    return null;
  }

  getApartmentDoors(aptId: number) {
    return this.http.get(`${environment.base_url}v1/apartment/` + aptId).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // getUsableDoors(doorType: number, doorUse: string): Door[] {
  //   let allDoors: Door[] = this.availableDoors;
  //   let filteredDoors: Door[] = [];
  //   //console.log(allDoors);
  //   for (let door of allDoors) {
  //     if (door["use_type"]) {
  //       if (door["use_type"].toLowerCase() == doorUse.toLowerCase()) {
  //         if (doorType == door["type"]["id"]) {
  //           filteredDoors.push(door);
  //         }
  //       }
  //     }
  //   }
  //   return filteredDoors;
  // // }

  // getDoors(queryParams) {
  //   queryParams["express"] = 1;
  //   return this.http
  //     .get(`${environment.base_url}v1/door`, { params: queryParams })
  //     .pipe(
  //       map((responseData) => {
  //         const postsArray: Door[] = [];

  //         for (const key in responseData["data"]["data"]) {
  //           //console.log(key);
  //           if (responseData["data"]["data"].hasOwnProperty(key)) {
  //             postsArray.push({
  //               ...responseData["data"]["data"][key],
  //               web_id: key,
  //             });
  //           }
  //         }
  //         //console.log(postsArray);
  //         return postsArray;
  //       })
  //     );
  // }
}
