import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

import { LocalStoreService } from './local-store.service';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // Only for demo purpose
  authenticated = false;

  constructor(private http: HttpClient, private store: LocalStoreService, private router: Router) {
    this.checkAuth();
  }

  checkAuth() {
    this.authenticated = !!this.store.getItem('currentUser');
  }

  getUser() {
    return of(this.store.getItem('currentUser'));
  }

  getToken() {
    return this.store.getItem('currentUser') == null ? null : this.store.getItem('currentUser').token;
  }

  getUserRole() {
    const user: any = this.store.getItem('currentUser');
    //console.log(user);
    return user.system_role['id'];//user.system_role['id']
  }

  forgotUpdate(formData){
    return this.http.post(`${environment.base_url}/auth/forgot/reset`,formData);
  }

  forgotToken(username){
    return this.http.post(`${environment.base_url}/auth/forgot/request`,{email:username});
  }

  getLogo(){
    return this.store.getItem('currentUser') && this.store.getItem('currentUser')['logo'] ? this.store.getItem('currentUser')['logo'] : "/assets/images/madmilano.svg";
  }

  signin(credentials) {
    return this.http.post<any>(`${environment.base_url}v1/login`, { email: credentials.email, password: credentials.password })
      .pipe(map(res => {
        const user = res.data.user;
        user['system_role'] = res.data.system_role;
        const token = res.data.api_key;
        const logo = res.data.showroom_logo;
          // login successful if there's a jwt token in the response
          if (user && token) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              this.store.setItem('currentUser', {...user, token, logo});
              this.authenticated = true;
          }

          return user;
      }));
  }
  signout() {
    this.authenticated = false;
    this.store.setItem('currentUser', null);
    localStorage.clear();
    this.router.navigateByUrl('/sessions/signin');
  }
}
