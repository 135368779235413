import { Injectable } from '@angular/core';
import { RoleAccess, Roles } from '../models/roles.model';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  private roles: Roles = {
    1: {
      role: 'Administrator',
      defaultRoute: 'orders/list',
      routeAccess: {
        settings:true,
        dashboard:true,
        orders:true,
        projects:true,
        developers:true,
        customers:true,
        doors:true,
        ['extra-categories']:true,
        ['door-types']:true,
        suppliers:true,
        employees:true,
        installations:true,
        reports:true,
        invoice:true
      },
      functionalityAccess: {
        quoteOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        salesOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        measuredOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        purchaseOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        installedOrders:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        partiallyInstalledOrders:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        completeOrders:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        canEditProject:true,
        canViewProject:true,
        canDeleteProject:true,
        canCreateProject:true
      }
    },
    2: {
      role: 'Accounts',
      defaultRoute: 'orders/list',
      routeAccess: {
        settings:true,
        dashboard:true,
        orders:true,
        projects:true,
        developers:true,
        customers:true,
        doors:true,
        ['extra-categories']:true,
        ['door-types']:true,
        suppliers:true,
        employees:true,
        installations:true,
        reports:true
      },
      functionalityAccess: {
        quoteOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        salesOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        measuredOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        purchaseOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        installedOrders:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        partiallyInstalledOrders:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        completeOrders:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        canEditProject:true,
        canViewProject:true,
        canDeleteProject:true,
        canCreateProject:true
      }
    },
    3: {
      role: 'Sales',
      defaultRoute: 'orders/list',
      routeAccess: {
        settings:true,
        dashboard:true,
        orders:true,
        projects:true,
        developers:true,
        customers:true,
        doors:false,
        ['extra-categories']:false,
        ['door-types']:false,
        suppliers:false,
        employees:false,
        installations:false,
        reports:false
      },
      functionalityAccess: {
        quoteOrder:{
          canEdit:true,
          canView:true,
          canDelete:false,
          canCreate:true
        },
        salesOrder:{
          canEdit:false,
          canView:true,
          canDelete:false,
          canCreate:true
        },
        measuredOrder:{
          canEdit:false,
          canView:true,
          canDelete:false,
          canCreate:false
        },
        purchaseOrder:{
          canEdit:false,
          canView:true,
          canDelete:false,
          canCreate:false
        },
        installedOrders:{
          canEdit:false,
          canView:true,
          canDelete:false,
          canCreate:false
        },
        partiallyInstalledOrders:{
          canEdit:false,
          canView:true,
          canDelete:false,
          canCreate:false
        },
        completeOrders:{
          canEdit:false,
          canView:true,
          canDelete:false,
          canCreate:false
        },
        canEditProject:true,
        canViewProject:true,
        canDeleteProject:true,
        canCreateProject:true
      }
    },
    4: {
      role: 'Measurer',
      defaultRoute: 'orders/list',
      routeAccess: {
        settings:true,
        dashboard:true,
        orders:true,
        projects:true,
        developers:true,
        customers:true,
        doors:true,
        ['extra-categories']:true,
        ['door-types']:true,
        suppliers:true,
        employees:true,
        installations:true,
        reports:true
      },
      functionalityAccess: {
        quoteOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        salesOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        measuredOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        purchaseOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        installedOrders:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        partiallyInstalledOrders:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        completeOrders:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        canEditProject:true,
        canViewProject:true,
        canDeleteProject:true,
        canCreateProject:true
      }
    },
    5: {
      role: 'Installer',
      defaultRoute: 'orders/list',
      routeAccess: {
        settings:true,
        dashboard:true,
        orders:true,
        projects:true,
        developers:true,
        customers:true,
        doors:true,
        ['extra-categories']:true,
        ['door-types']:true,
        suppliers:true,
        employees:true,
        installations:true,
        reports:true
      },
      functionalityAccess: {
        quoteOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        salesOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        measuredOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        purchaseOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        installedOrders:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        partiallyInstalledOrders:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        completeOrders:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        canEditProject:true,
        canViewProject:true,
        canDeleteProject:true,
        canCreateProject:true
      }
    },
    6: {
      role: 'Gypsum',
      defaultRoute: 'orders/list',
      routeAccess: {
        settings:true,
        dashboard:true,
        orders:true,
        projects:true,
        developers:true,
        customers:true,
        doors:true,
        ['extra-categories']:true,
        ['door-types']:true,
        suppliers:true,
        employees:true,
        installations:true,
        reports:true
      },
      functionalityAccess: {
        quoteOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        salesOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        measuredOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        purchaseOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        installedOrders:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        partiallyInstalledOrders:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        completeOrders:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        canEditProject:true,
        canViewProject:true,
        canDeleteProject:true,
        canCreateProject:true
      }
    },
    7: {
      role: 'Tiling',
      defaultRoute: 'orders/list',
      routeAccess: {
        settings:true,
        dashboard:true,
        orders:true,
        projects:true,
        developers:true,
        customers:true,
        doors:true,
        ['extra-categories']:true,
        ['door-types']:true,
        suppliers:true,
        employees:true,
        installations:true,
        reports:true
      },
      functionalityAccess: {
        quoteOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        salesOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        measuredOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        purchaseOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        installedOrders:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        partiallyInstalledOrders:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        completeOrders:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        canEditProject:true,
        canViewProject:true,
        canDeleteProject:true,
        canCreateProject:true
      }
    },
    8: {
      role: 'Supplier',
      defaultRoute: 'orders/list',
      routeAccess: {
        settings:true,
        dashboard:true,
        orders:true,
        projects:true,
        developers:true,
        customers:true,
        doors:true,
        ['extra-categories']:true,
        ['door-types']:true,
        suppliers:true,
        employees:true,
        installations:true,
        reports:true
      },
      functionalityAccess: {
        quoteOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        salesOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        measuredOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        purchaseOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        installedOrders:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        partiallyInstalledOrders:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        completeOrders:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        canEditProject:true,
        canViewProject:true,
        canDeleteProject:true,
        canCreateProject:true
      }
    },
    9: {
      role: 'Client',
      defaultRoute: 'orders/list',
      routeAccess: {
        settings:true,
        dashboard:true,
        orders:true,
        projects:true,
        developers:true,
        customers:true,
        doors:true,
        ['extra-categories']:true,
        ['door-types']:true,
        suppliers:true,
        employees:true,
        installations:true,
        reports:true
      },
      functionalityAccess: {
        quoteOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        salesOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        measuredOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        purchaseOrder:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        installedOrders:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        partiallyInstalledOrders:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        completeOrders:{
          canEdit:true,
          canView:true,
          canDelete:true,
          canCreate:true
        },
        canEditProject:true,
        canViewProject:true,
        canDeleteProject:true,
        canCreateProject:true
      }
    },
  };

  constructor() { }

  getAccess(role: number): RoleAccess {
    return <RoleAccess>this.roles[role];
  }
}
